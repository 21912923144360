.p-float-label{
    display: inline-block;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 5px;
  
    .p-inputtext{
      width: 100%;
    }
  }

p-inputnumber {
    width: 100%;
    
    .p-inputnumber {
      width: 100%;
  }
}

.p-float-label input:focus ~ label, 
.p-float-label input.p-filled ~ label, 
.p-float-label textarea:focus ~ label, 
.p-float-label textarea.p-filled ~ label, 
.p-float-label .p-inputwrapper-focus ~ label, 
.p-float-label .p-inputwrapper-filled ~ label {
    left: 0;
    font-size: 14px;
    color: var(--text-color);
}

.p-button {
  background: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  &:enabled:hover {
    background: var(--ion-color-primary-tint);
    border-color: var(--ion-color-primary-tint);
}
  .p-button-icon {
    color: #FFF;
  }
}

// inputswitch
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--ion-color-primary);
}

p-inputswitch{
  line-height: 0;
  vertical-align: middle;
}

// datepicker
.p-datepicker:not(.p-datepicker-inline) {
  background: #FFF;
  color: #000;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, 
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: black;
}
.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ff3d00;
  background: rgb(255 127 89 / 30%);
}
.p-datepicker table td.p-datepicker-today > span {
  background: rgb(33 150 243 / 10%);
  color: #2196F3;
  border-color: transparent;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: rgb(255 127 89 / 10%);
}

// dropdown
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--ion-color-primary);
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--ion-color-primary-tint);
  border-color: var(--ion-color-primary);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
  --text-color: var(--ion-color-primary);
  background: rgb(255 127 89 / 30%);
}

//.p-accord ion
p-accordion .p-accordion{
  .p-accordion-header .p-accordion-header-link,
  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #f7f7f7;
    border-color: #d7d7d7;
    color: #212529;
  }
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
  .p-accordion-header.p-highlight:not(.p-disabled):hover .p-accordion-header-link {
    background: #e7e7e7;
    border-color: #e7e7e7;
    color: #212529;
  }
  
}

p-accordion.filled .p-accordion {
  .p-accordion-header .p-accordion-header-link,
  .p-accordion-header.p-highlight .p-accordion-header-link {
    background: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
    color: #ffffff;
  }
  .p-accordion-header:not(.p-disabled):hover .p-accordion-header-link {
    background: #9c452a;
    border-color: #9c452a;
    color: #ffffff;
  }
}

p-accordion.dynamicFieldGroup .p-accordion-content{
  padding-top: 1px;
  padding-bottom: 0;
}