/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "theme/primeng.scss";
@import "theme/primeng-custom.scss";

@import '@fortawesome/fontawesome-free/css/all.min.css';
@import '@fortawesome/fontawesome-free/css/v4-shims.min.css';

@import "swiper/css";

@import "theme/leaflet.scss";

@import "@fancyapps/ui/dist/fancybox/fancybox.css";

body {
  color: var(--text-color);
  font-family: Montserrat , sans-serif;
}

html.with-fancybox body.hide-scrollbar {
  width: 100%;
}

h1{
  font-size: 20px!important;
}
h2{
  font-size: 18px!important;
}
h3{
  font-size: 16px!important;
}
h4{
  font-size: 14px!important;
}
.font-w-100{
  font-weight: 100;
}
.font-w-200{
  font-weight: 200;
}
.font-w-300{
  font-weight: 300;
}
.font-w-400{
  font-weight: 400;
}
.font-w-500{
  font-weight: 500;
}
.font-w-600{
  font-weight: 600;
}
.font-w-700{
  font-weight: 700;
}
.font-w-800{
  font-weight: 800;
}
.container-fotos{
  .rute{
    .bold{
      font-weight: 600;
    }
  }
}


.mont-medium{
  font-weight: 500 !important;
}
.mont-bold{
  font-weight: bold !important;
}
.mont-semiBold{
  font-weight: 600 !important;
}
.mont-italic{
  font-style: italic !important;
}
input:focus, textarea:focus, select:focus{
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.header-md::after{
  /*background-image: none;
  border-top-style: solid;
  border-width: 0.1px;*/
}
.p-inline-start{
  padding-inline-start: 12px!important;
}
.content-sidebar{
  --ion-background-color: #eeeeee;
}


ion-button.gaia-button,
ion-button.orange-button {
  --color: white !important;
  --color-activated: white !important;
  --color-hover: white !important;
  --background-hover: currentColor !important;

  background-color: var(--primary-color);
  --background: transparent;
  --background-hover-opacity: 0.08;

  --border-style: none;
  border-radius: 10px !important;

  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 20px;
  --padding-end: 20px;

  font-size: 16px;
  font-weight: 600;
  text-transform: none;

  --box-shadow: none;
  box-shadow: 0 3px 3px rgba(0,0,0,0.14), 0 3px 3px rgba(0,0,0,0.14);

  min-height: 40px;
}

ion-button.gaia-button.secondary{
  background-color: var(--ion-color-secondary);
}

ion-app {
  ion-header::after{
    background-image: none;
    display: none;
  }

  app-home,
  app-configuration,
  app-form-create-proyecto,
  app-form-create-cala,
  app-proyecto,
  app-registros,
  app-cala,
  app-unidad,
  app-form-cov,
  app-form-samples,
  app-ground-type-modal,
  app-form-create-user,
    app-dynamic-template{
    ion-header {
      height: 110px;

      .header-background {
        background-color: transparent;
      }

      ion-toolbar {
        --border-width: 0px!important;
        --background: transparent;
        height: 100%;
        display: flex;
        .toolbar-background{
          background-color: white!important;
        }
      }
    }

    .back-button{
      background-color: var(--primary-color);
      color: white;
      border-radius: 30px;
      height: 35px;
      min-height: 35px;
      width: 35px;
      justify-content: center;
      ion-button{
        --color: initial;
      }
    }

  }

}
ion-split-pane{
  --side-max-width: 100px;
}
:root {
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 22px;
}
$container-max-widths: (
  xs: 380px,
  sm: 540px,
  md: 720px,
  lg: 720px,
  xl: 720px,
  xxl: 720px
);
.title-menu-page{
  font-size: 1.4em;
}

$primary:       #795548;
$secondary:     #94B49F;
$green-900:     #125B50;
$gray-100:      #FAF5E4;


@import 'assets/fonts/montserrat/Montserrat.css';

@import 'bootstrap/scss/bootstrap';

html body.swal2-height-auto {
  height: 100% !important;
}

html .swal2-styled:focus{
box-shadow: none;
}


.empty-state-rounded{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  margin-top: 50px;

  color: #a4a4a4;

  h2{
    font-weight: 500 !important;
    color: inherit !important;
  }

  .icon-wrapper {

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-bottom: 40px;

    .circle {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 50%;
        line-height: 0;

        ion-icon,
        i {
            font-size: 10em;
            color: rgba(0, 0, 0, 0.2);
        }

        ion-icon {
          padding: 20px;
        }

    }
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  i {
    margin-right: 10px;
  }
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffbe00;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #ff919d;
}

.required_icon{
  color: red;
}

.item-sliding-active-slide .item {
  pointer-events: inherit;
}

//Fonts sizes

.text-xxs{
  font-size: 0.6em;
}
.text-xs{
  font-size: 0.7em;
}
.text-s{
  font-size: 0.8em;
}
.text-m{
  font-size: 0.9em;
}
.text-l{
  font-size: 1em;
}
.text-xl{
  font-size: 1.2em;
}
.text-xxl{
  font-size: 1.4em;
}

// Modals

@media only screen and (min-width: 768px) and (min-height: 600px)
{
  .modal-default:not(.ion-datetime-button-overlay) {
    --width: 90%;
    --height: 90%;
    --max-width: 1200px;
    --border-radius: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px)
{
  .modal-default:not(.ion-datetime-button-overlay) {
    --width: 90%;
    --height: 90%;
    --border-radius: 15px;
  }
}
.ion-datetime-button-overlay{
  --box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  --border-style: solid;
  --border-width: 1px;
  --border-color: rgba(0, 0, 0, 0.25);
}
//--

// Images Gallery

.modal-images-gallery{
  --width: 100%;
  --height: 100%;
}

// Header buttons

ion-header {
  ion-button.button-outline{
    height: 40px !important;
    text-transform: none;
    --border-style: solid;
    --border-width: 1px;
  }
}
// Empty wrapper

.empty-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  margin-top: 50px;

  color: #a4a4a4;

  .icon-wrapper {

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-bottom: 40px;

    .circle {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 50%;
        line-height: 0;

        ion-icon,
        i {
            font-size: 10em;
            color: rgba(0, 0, 0, 0.2);
        }

    }
  }
}

// -----

// Counters ---

ion-item .counters{
  display: flex;
  align-items: center;

  > div{
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    ion-icon{
      margin-right: 2px;
    }
    span{
      display: inline-block;
      min-width: 0.5rem;
      text-align: center;
    }
  }
}
.little-modal-sig{
  --height: 600px!important;
}

@media screen and (min-width: 991px){
  .no-in-desktop{
    display: none!important;
  }
  ion-header {
    ion-toolbar {
      border-bottom: solid;
      border-bottom-width: 2px;
      border-color: var(--primary-color);
    }
  }
}

@media screen and (max-width: 991px){
  .no-in-responsive{
    display: none!important;
  }
}

app-markers-map.inside-modal .leaflet-top{
  top: 60px;
}

.users-config{
  .datatable-header{
    font-weight: 700;
    margin-bottom: 10px;
  }
}
//----
